<template>
  <div id="enforce-multi-factor-options">
    <auth-forms-wrapper width="392px" show-links card-classes="px-0">
      <template #form>
        <div id="enforce-multi-factor-options__details" class="mb-6">
          <h2
            class="body-1 text--primary font-weight-large secondary--font pb-6"
          >
            Multi-Factor Authentication Required
          </h2>
          <div class="text--secondary body-2 pb-4 text-left">
            Maropost requires you to set up Multi-Factor Authentication for your
            account to improve security.
          </div>
          <div class="text--secondary body-2 text-left">
            <p class="mb-2">
              You will be required to provide two forms of identification when
              logging in:
            </p>
            <ol class="pl-4">
              <li>Your password</li>
              <li>
                An authentication code via text message or a Security app
              </li>
            </ol>
          </div>
        </div>

        <v-form v-model="isFormValid" @submit.prevent="onSubmitHandler">
          <v-row no-gutters class="text-left d-flex justify-start">
            <v-col cols="12" class="d-flex justify-start">
              <div class="form-group">
                <label
                  for="select-mfa-options"
                  class="subtitle-2 font-weight-large"
                >
                  Select your authentication method:</label
                >
                <v-radio-group
                  mandatory
                  hide-details="auto"
                  class="mt-1"
                  id="select-mfa-options"
                  v-model="selectedMfaOption"
                  :rules="[required('Authentication method')]"
                >
                  <template v-for="{ name, value, identifier } in mfaOptions">
                    <v-radio
                      :key="name"
                      :label="name"
                      :value="value"
                      v-track="identifier"
                    >
                      <template #label>
                        <span
                          v-text="name"
                          class="text--primary body-2 font-weight-regular"
                        />
                      </template>
                    </v-radio>
                  </template>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <auth-form-buttons
            @back="onCancel"
            wrapperClasses="mt-6"
            :is-loading="isLoading"
            submit-btn-text="continue"
            :is-form-valid="isFormValid"
            back-btn-track-id="select-mfa-option-back-button"
            submit-btn-track-id="select-mfa-option-continue-button"
          />
        </v-form>
      </template>
    </auth-forms-wrapper>
  </div>
</template>

<script>
import { MFA_OPTIONS } from "@/constants/app";
import { required } from "@/validators/form-validators";
import AuthFormButtons from "@/components/shared/AuthFormButtons.vue";

import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

/**
 * Shows multi factor options for user to select while login if Mfa is not enabled
 * @author {Jatin Kamboj}
 */
export default {
  name: "SelectMultiFactorOptionsForm",
  /**
  |--------------------------------------------------
  | Custom Events
  |--------------------------------------------------
  */
  emits: ["enforce-mfa", "cancel"],
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { AuthFormsWrapper, AuthFormButtons },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    const mfaOptions = [...MFA_OPTIONS];

    return {
      mfaOptions,
      isLoading: false,
      isFormValid: false,
      selectedMfaOption: mfaOptions[0].value,
    };
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    required,
    /**
     * @emits cancel event in parent component
     */
    onCancel() {
      this.$emit("cancel");
    },
    /**
     * Form submit handler
     * @emits enforce-mfa with name of mfa option selected by the user
     */
    onSubmitHandler() {
      this.$emit("enforce-mfa", this.selectedMfaOption);
    },
  },
};
</script>

<style lang="scss">
#select-mfa-options {
  .v-radio {
    display: flex;
    align-items: center;
  }
}
</style>
