<template>
  <section id="login-page">
    <login-form
      @enter-otp="initiateMfa"
      @enter-totp="onEnterAuthenticatorCode"
      @enforce-mfa="enforceMfa = $event"
      v-if="!isUserAuthenticated && !enforceMfa"
    />

    <!-- Show mfa selection options if mfa is not setup -->
    <select-multi-factor-options-form
      @cancel="reauthenticateHandler"
      @enforce-mfa="selectedMfaMethod = $event"
      v-else-if="enforceMfa && !selectedMfaMethod"
    />

    <!-- Enforces Authenticator app mfa setup if user have selected -->
    <enforce-authenticator-app-mfa-form
      @cancel="selectedMfaMethod = ''"
      v-else-if="enforceMfa && isAuthenticatorAppSelected"
    />

    <auth-forms-wrapper
      width="392px"
      card-classes="mt-0"
      wrapper-classes="px-2 py-6"
      v-else-if="enforceMfa && isTextMessageMfaSelected"
    >
      <template #form>
        <enroll-multi-factor-form
          is-mfa-required
          is-heading-primary
          :show-header="false"
          wrapper-class="pa-0"
          :show-phone-info="false"
          content-class="text-left"
          @otp-sent="isOtpSent = $event"
          @close-dialog="reauthenticateHandler"
          captcha-wrapper-class="justify-center"
          @disable-submit-btn="isEnrollMfaFormValid = $event"
          phone-number-input-class="login-phone-number__wrapper"
          show-resend-code-option
          heading="Maropost requires you to set up Multi-Factor Authentication for your account to improve security."
        >
          <template #header>
            <v-row class="mb-1">
              <v-col cols="12" lg="12">
                <h3
                  class="
                    pb-4
                    body-1
                    text--primary
                    font-weight-large
                    secondary--font
                  "
                >
                  Text Message Authentication
                </h3>
              </v-col>
            </v-row>
          </template>
          <template #successMessage v-if="successMessage">
            <v-alert
              :icon="false"
              dense
              :color="$appConfig.commonColors.infoLight"
              class="break-word"
            >
              <v-row no-gutters>
                <v-col class="d-flex align-center">
                  <v-icon :color="$appConfig.commonColors.info">
                    mdi-information-outline
                  </v-icon>

                  <span class="text--primary body-2 d-flex text-left pl-2">
                    {{ successMessage }}
                  </span>
                </v-col>
              </v-row>
            </v-alert>
          </template>
          <template #buttons v-if="!isOtpSent">
            <auth-form-buttons
              :is-loading="false"
              wrapperClasses="mt-6"
              submit-btn-text="continue"
              @back="selectedMfaMethod = ''"
              :is-form-valid="!isEnrollMfaFormValid"
              back-btn-track-id="enforce-text-message-mfa-back-btn"
              submit-btn-track-id="enforce-text-message-mfa-continue-btn"
            />
          </template>
        </enroll-multi-factor-form>
      </template>
    </auth-forms-wrapper>

    <auth-forms-wrapper
      width="392px"
      card-classes="px-0"
      v-else-if="isAuthenticatorAppMfaEnabled && enterTotpCode"
    >
      <template #form>
        <enter-otp-form
          show-support
          :is-loading="isLoading"
          :error-message="errorMessage"
          :phone-number="mfaPhoneNumber"
          @submit="verifyAuthenticatorCode"
          @reauthenticate="reauthenticateHandler"
          heading="Multi-Factor Authentication Required"
          :track-id="'login-page-authenticator-submit-btn'"
        >
          <template #subHeading>
            <p class="body-2 text--secondary pt-6 text-left mb-1">
              Enter the 6-digit verification code from your security app to
              continue logging in.
            </p>
          </template>
        </enter-otp-form>
      </template>
    </auth-forms-wrapper>

    <auth-forms-wrapper v-else width="392px" card-classes="px-0">
      <template #form>
        <enter-otp-form
          show-support
          @destroyed="clearResendCodeTimer"
          heading="Multi-Factor Authentication Required"
          prepend-text="continue logging in."
          @submit="verifyOtp"
          :error-message="errorMessage"
          :phone-number="mfaPhoneNumber"
          :is-loading="isLoading"
          @reauthenticate="reauthenticateHandler"
          :track-id="'login-page-otp-submit-btn'"
          show-resend-code-option
          :is-code-sending="isCodeSending"
          :is-resend-code-allowed="isResendCodeAllowed"
          :resend-verification-code="resendVerificationCode"
        />
      </template>
    </auth-forms-wrapper>

    <salesforce-saml-assertion-form
      @show-loader="isLoading = $event"
      v-if="!isEmpty(samlResponse)"
      :action="samlResponse.saml_acs_url"
      :relay-state="samlResponse.relay_state"
      :saml-response="samlResponse.saml_response"
    />

    <div id="recaptcha-container" />
    <!-- <v-btn color="primary" @click="disableTotp">Disable TOTP</v-btn> -->
  </section>
</template>

<script>
import {
  auth,
  getCurrentUser,
  getCurrentUserIdToken,
  sendVerificationEmail
} from "@/services/auth";
import { 
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator
  } from 'firebase/auth'
import { isEmpty, defer, isType } from "@/utils";

import LoginForm from "@/components/forms/LoginForm.vue";
import EnterOTPForm from "@/components/forms/EnterOTPForm.vue";
import ResendFirebaseTextCodeMixin from "@/mixins/ResendFirebaseTextCode.mixin";

import { mapActions, mapGetters, mapMutations } from "vuex";
import { MFA_OPTIONS, AUTH_ERROR_CASE_MESSAGES } from "@/constants/app";
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

import EnrollMultiFactorForm from "@/components/forms/EnrollMultiFactorForm.vue";
import SalesforceSamlAssertionForm from "@/components/forms/SalesforceSamlAssertionForm.vue";
import SelectMultiFactorOptionsForm from "@/components/forms/SelectMultiFactorOptionForm.vue";

// import { createUserProfile } from "@/services/users";
import AuthFormButtons from "@/components/shared/AuthFormButtons.vue";
import EnforceAuthenticatorAppMfaForm from "@/components/forms/EnforceAuthenticatorAppMfaForm.vue";

/**
 * Login view
 */
export default {
  name: "Login",
  /**
  |--------------------------------------------------
  | Mixins
  |--------------------------------------------------
  */
  mixins: [ResendFirebaseTextCodeMixin],
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    LoginForm,
    AuthFormsWrapper,
    EnrollMultiFactorForm,
    SalesforceSamlAssertionForm,
    "enter-otp-form": EnterOTPForm,
    SelectMultiFactorOptionsForm,
    EnforceAuthenticatorAppMfaForm,
    AuthFormButtons,
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      otp: "",
      timerRef: null,
      isLoading: false,
      isUserAuthenticated: false,
      smsVerificationId: "",
      errorMessage: "",
      authResolver: null,
      recaptchaVerifier: null,
      recaptchaVerified: false,
      isRecaptchaRendered: false,
      enforceMfa: false,
      isOtpSent: false,
      isEnrollMfaFormValid: true,
      successMessage: "",
      selectedMfaMethod: "",
      enterTotpCode: false,
    };
  },

  /**
  |--------------------------------------------------
  | Watching propeties
  |--------------------------------------------------
  */
  watch: {
    /**
     * Watches samlResponse property and shows loader
     * Therefore sets a callback function to hide loader after 5000 seconds
     * in th case of saml assertion
     */
    samlResponse(val) {
      if (!isEmpty(val)) {
        this.setIsLoading(true);
        this.timerRef = defer(() => this.setIsLoading(false), 5000);
      }
    },
    /**
     * Watches enforceMfa and triggers email verification email to the user
     * Before enforcing mfa for a user
     */
    async enforceMfa(val) {
      if (val && isType(val, "boolean")) {
        if (!this.enForceMfaUserDetails?.emailVerified) {
          await this.sendEmailVerificationEmail();
        }
      }
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      userProfile: "auth/userProfile",
      samlResponse: "auth/samlResponse",
      enForceMfaUserDetails: "auth/enForceMfaUserDetails",
      isTextBasedMfaEnabled: "auth/isTextBasedMfaEnabled",
      isAuthenticatorAppMfaEnabled: "auth/isAuthenticatorAppMfaEnabled",
    }),
    /**
     * Is Authenticator app MFA setup selected by the user
     * @type {Boolean}
     */
    isAuthenticatorAppSelected() {
      const [, authenticatorApp] = MFA_OPTIONS;
      return authenticatorApp.value === this.selectedMfaMethod;
    },
    /**
     * Is text message based setup selected by the user
     * @type {Boolean}
     */
    isTextMessageMfaSelected() {
      const [textMessage] = MFA_OPTIONS;
      return textMessage.value === this.selectedMfaMethod;
    },

    /**
     * Determines if SAML assertion id is present in query params
     * @type {Boolean}
     */
    hasSamlAssertionId() {
      return !!this.$route.query?.maropost_saml_id;
    },
    multiFactorDetails() {
      if (!this.authResolver) return {};
      return this.authResolver.hints[0];
    },
    mfaPhoneNumber() {
      return this.multiFactorDetails?.phoneNumber;
    },
    removeGipUser() {
      return (
        (!this.isAuthenticatorAppMfaEnabled || !this.isTextBasedMfaEnabled) &&
        !this.isLoggedIn
      );
    },
    /**
     * Checks whether user is logged in or not from the localstorage persisted data
     * @returns {Boolean} Is logged in or not
     */
    isLoggedIn() {
      return (
        JSON.parse(localStorage.getItem("maropost-identity"))?.auth
          ?.isLoggedIn ?? false
      );
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    isEmpty,
    ...mapMutations({
      removeEnforcedUserProfile: "auth/REMOVE_ENFORCE_USER_PROFILE_DETAILS",
      setIsSessionVerifying: "auth/SET_IS_VERIFYING_SESSION",
    }),
    /**
     * Maps actions in the component
     */
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      setCookieSession: "auth/setCookieSession",
      logoutEnforceMfaUser: "auth/logoutEnforceMfaUser",
      setCurrentUserDetails: "auth/setCurrentUserDetails",
      linkPreviousUserProvider: "auth/linkPreviousUserProvider",
    }),
    /**
     * Resets all the properties in the component to reauthenticate the user
     * @listens submit
     */
    async reauthenticateHandler() {
      try {
        this.setIsLoading(true);
        this.otp = "";
        this.errorMessage = "";
        this.isUserAuthenticated = false;
        this.authResolver = null;
        this.smsVerificationId = "";
        this.enforceMfa = false;
        this.isOtpSent = false;
        this.isEnrollMfaFormValid = true;
        this.successMessage = "";
        this.selectedMfaMethod = "";
        this.enterTotpCode = false;
        await this.verifyDestroyGipUser();
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Initialises the Multi Factor authentication of the user
     */
    async initiateMfa(resolver) {
      this.isUserAuthenticated = true;
      this.authResolver = resolver;
      await this.verifyRecaptchaVerifier();
      this.enrollMultiFactor();
    },
    /**
     * Handles otp verification of the user
     * @param {String} otp OTP enetered by the uset
     */
    async verifyOtp(otp) {
      this.otp = otp;
      this.enrollMultiFactor();
    },
    /**
     * Sets is loading state to show the loader to the end user
     * @param {Boolean} val Boolean value of isLoading property
     */
    setIsLoading(val) {
      this.isLoading = val;
    },
    /**
     * Verifies and displayes recaptcha
     */
    async verifyRecaptchaVerifier() {
      if (this.isRecaptchaRendered) return;

      try {
        this.setIsLoading(true);

        const CAPTACHA_CONTAINER_ID = "recaptcha-container";
        this.recaptchaVerifier = new RecaptchaVerifier(auth,
          CAPTACHA_CONTAINER_ID,
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
              this.recaptchaVerified = !!response;
            },
          }
        );

        await this.recaptchaVerifier.render();
      } catch (error) {
        this.isRecaptchaRendered = false;
        this.showError(error);
      } finally {
        this.isRecaptchaRendered = true;
        this.setIsLoading(false);
      }
    },
    /**
     * Enrolls a new user phone number as Multi factor authentication
     * @listens submit Handles form submition and user phone verification and mutlti factor activation
     */
    async enrollMultiFactor() {
      try {
        this.setIsLoading(true);

        if (!this.smsVerificationId) {
          this.sendVerificationMessage();
        } else if (this.otp) {
          await this.verifySmsCode();
        }
      } catch (error) {
        this.setIsLoading(false);
        this.showError(error);
      } finally {
        if (!this.hasSamlAssertionId) this.setIsLoading(false);
      }
    },
    errorHandler(error) {
      this.showError(error);
    },
    /**
     * Sends verification code to user phone number in the form
     */
    async sendVerificationMessage() {
      try {
        this.setIsLoading(true);
        var phoneAuthProvider = new PhoneAuthProvider(auth);

        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          multiFactorHint: this.multiFactorDetails,
          session: this.authResolver.session,
        };
        // Send SMS verification code.
        this.smsVerificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          this.recaptchaVerifier
        );
        this.resetResendCodeVerificationTimer();
      } catch (error) {
        this.showError(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    onEnterAuthenticatorCode(val) {
      this.enterTotpCode = val;
      this.isUserAuthenticated = true;
    },
    /**
     * Verify user verification code code
     * @description Ask user for the verification code.
     */
    async verifySmsCode() {
      try {
        this.setIsLoading(true);

        var cred = PhoneAuthProvider.credential(
          this.smsVerificationId,
          this.otp
        );
        var multiFactorAssertion =
          PhoneMultiFactorGenerator.assertion(cred);

        // Complete enrollment. This will update the underlying tokens
        // and trigger ID token change listener.
        const userDetails = await this.authResolver.resolveSignIn(
          multiFactorAssertion
        );
        await this.linkPreviousUserProvider({
          user: userDetails.user,
          email: userDetails.user?.email,
        });
        await this.setCurrentUserDetails(userDetails.user);
      } catch (error) {
        this.setIsLoading(false);
        this.showError(error);
      } finally {
        if (!this.hasSamlAssertionId) this.setIsLoading(false);
      }
    },
    /**
     * Computes the error message of the error encountered in the firebase service
     * @param {Error} error Error object
     */
    showError(error) {
      const message = AUTH_ERROR_CASE_MESSAGES[error?.code] ?? error?.message;
      this.errorMessage = message;
    },
    /**
     * Sends email verification email to the logged in user
     */
    async sendEmailVerificationEmail() {
      try {
        this.setIsLoading(true);
        await sendVerificationEmail();
        this.successMessage =
          "Please verify your email before proceeding further.";
      } catch (error) {
        this.showError(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Verifies and destroys gip user in the memory if page gets reloaded while
     * mfa is being setup
     */
    verifyDestroyGipUser() {
      if (this.removeGipUser) {
        Promise.allSettled([
          this.logoutEnforceMfaUser(),
          this.removeEnforcedUserProfile(),
        ]);
      }
    },
    /**
     * @todo Implement excpetion handling for error cases
     */
    async verifyAuthenticatorCode(code) {
      if (!code) return;
      try {
        await this.setIsSessionVerifying(true);
        this.isLoading = true;
        const idToken = await getCurrentUserIdToken();
        await this.setCookieSession({ code, idToken });

        let userDetails = getCurrentUser() ?? {};
        userDetails = { ...userDetails, code, setCookie: false };

        await this.linkPreviousUserProvider({
          user: userDetails.user,
          email: userDetails?.email,
        });
        await this.setCurrentUserDetails(userDetails);
      } catch (error) {
        this.authenticatorAppErrorHandler(error);
      } finally {
        await this.setIsSessionVerifying(false);
        this.isLoading = false;
      }
    },
    authenticatorAppErrorHandler(error) {
      const isCodeInvalid =
        error?.response?.data?.error === "Invalid TOTP code";
      if (isCodeInvalid) error.code = "auth/invalid-totp-code";

      error.message =
        error?.response?.data?.error ??
        error.message ??
        this.$appConfig.commonErrorMessage;
      this.showError(error);
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    if (!this.hasSamlAssertionId) {
      window.addEventListener("beforeunload", this.verifyDestroyGipUser);
    }
  },
  /**
  |--------------------------------------------------
  | Detsroyed lifecycle hook
  |--------------------------------------------------
  */
  destroyed() {
    this.timerRef && clearTimeout(this.timerRef);
    if (!this.hasSamlAssertionId) {
      window.removeEventListener("beforeunload", this.verifyDestroyGipUser);
    }
  },
};
</script>
