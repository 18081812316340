<template>
  <div id="enforce-authenticator-app-mfa-form">
    <auth-forms-wrapper
      width="392px"
      card-classes="px-0"
      v-if="!showOtpScreen"
      wrapper-classes="pt-6 pb-6 px-6 h-100"
    >
      <template #form>
        <authenticator-app-mfa-setup-form
          :instructions="instructions"
          title="Security App Authentication"
          subtitle="Follow the steps below in order to setup a security app."
        >
          <template #instruction_2>
            <div class="barcode__wrapper">
              <template v-if="!!barcodeImage && !!secretCode">
                <div class="d-flex justify-center pb-4 barcode-image__wrapper">
                  <v-img
                    width="160px"
                    height="160px"
                    max-width="160px"
                    max-height="160px"
                    :src="barcodeImage"
                    alt="Authenticator App Barcode"
                    class="authenticator-app-barcode__image"
                  />
                </div>
                <div
                  class="caption text--primary my-4 text-center text-capitalize"
                >
                  {{ secretCode }}
                </div>
              </template>
            </div>
          </template>
          <template #footer>
            <auth-form-buttons
              is-form-valid
              :is-loading="isLoading"
              wrapperClasses="mt-6"
              @back="$emit('cancel')"
              submitBtnType="button"
              @submit="submitForm"
              submit-btn-text="continue"
              back-btn-track-id="setup-authenticator-app-mfa-back-button"
              submit-btn-track-id="setup-authenticator-app-mfa-continue-button"
            />
          </template>
        </authenticator-app-mfa-setup-form>
      </template>
    </auth-forms-wrapper>

    <auth-forms-wrapper v-else card-classes="px-0" width="392px" #form>
      <enter-otp-form
        :error-message="authenticatorError"
        phone-number=""
        :is-loading="isLoading"
        @submit="initiateMfaSetup"
        @reauthenticate="reauthenticateHandler"
      >
        <template #heading>
          <h2 class="body-1 text--primary font-weight-large secondary--font">
            Security App Authentication
          </h2>
        </template>
        <template #subHeading>
          <p class="body-2 text--secondary pt-6 text-left mb-1">
            Enter the 6-digit verification code from your security app to
            continue logging in.
          </p>
        </template>
      </enter-otp-form>
    </auth-forms-wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getCurrentUser,
  getCurrentUserIdToken,
  getAuthenticatorAppBarcode,
} from "@/services/auth";
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

import EnterOTPForm from "@/components/forms/EnterOTPForm.vue";
import AuthFormButtons from "@/components/shared/AuthFormButtons.vue";
import AuthenticatorAppMfaSetupForm from "@/components/forms/AuthenticatorAppMfaSetupForm.vue";

import { RESPONSE_CODES, AUTH_ERROR_CASE_MESSAGES } from "@/constants/app.js";

/**
 * @description Enforces authenticator app mfa setup during login
 * when user have not setup mfa for his account
 * @author {Jatin Kamboj}
 */
export default {
  name: "EnforceAuthenticatorAppMfaForm",
  /**
  |--------------------------------------------------
  | Custome events emitted by the component
  |--------------------------------------------------
  */
  emits: ["cancel"],
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    AuthFormsWrapper,
    AuthenticatorAppMfaSetupForm,
    "enter-otp-form": EnterOTPForm,
    AuthFormButtons,
  },

  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      barcodeImage: "",
      secretCode: "",
      isLoading: false,
      showOtpScreen: false,
      authenticatorError: "",
      instructions: [
        "Open your app and add new account.",
        "Scan the QR code below using the security app or enter the code manually.",
        "Click continue to enter the 6-digit verification code from the app.",
      ],
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      token: "auth/token",
    }),
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setCurrentUserDetails: "auth/setCurrentUserDetails",
      setupAuthenticatorAppMfa: "auth/verifyAndSetupAuthenticatorAppMfa",
    }),
    /**
     * Fetches Authenticator app setup barcode image
     */
    async fetchAuthenticatorAppBarcode() {
      try {
        this.isLoading = true;
        const token = await getCurrentUserIdToken();
        const { data } = await getAuthenticatorAppBarcode(token);
        this.barcodeImage = `data:image/png;base64, ${data?.imageData}`;
        this.secretCode = data?.secret ?? "";
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Submits the form in parent component
     * @emits submit
     */
    submitForm() {
      this.showOtpScreen = true;
    },
    /**
     * Reauthenticates the user and reinitialises the Mfa setup process
     * @listens reauthenticate
     */
    reauthenticateHandler() {
      this.showOtpScreen = false;
      this.fetchAuthenticatorAppBarcode();
    },
    /**
     * Verifies the Authenticator app code enetered by the user
     * If the code is correct then user gets successfully logged into the application
     * @param {String} code Code entered by the user
     */
    async initiateMfaSetup(code) {
      if (!code) return;
      try {
        this.authenticatorError = "";
        this.isLoading = true;
        await this.setupAuthenticatorAppMfa({ code });

        const userDetails = await getCurrentUser();
        await this.setCurrentUserDetails({ ...userDetails, code });
      } catch (error) {
        const isTotpInvalid =
          error?.response?.status === RESPONSE_CODES.unProcessed;
        if (isTotpInvalid) {
          this.authenticatorError =
            AUTH_ERROR_CASE_MESSAGES["auth/invalid-totp-code"];
        } else {
          this.authenticatorError =
            error?.response?.data?.errors ?? error?.message;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.fetchAuthenticatorAppBarcode();
  },
};
</script>
