<template>
  <div id="auth-identity-login-widget" class="mb-3">
    <div class="d-flex justify-center mt-2 mb-4 overline">OR</div>
    <v-row>
      <v-col class="pt-0" cols="12" lg="12">
        <v-btn
          block
          large
          outlined
          type="button"
          color="secondary"
          v-track="'google-sign-in-btn'"
          class="button font-weight-bold text--secondary"
          @click="signInUserWithGooglePopUp"
        >
          <img
            class="pr-3"
            width="30px"
            height="30px"
            alt="Google icon"
            id="google-sign-in-login"
            :src="$images.googleIcon"
          />
          Sign in with google
        </v-btn>
        <v-btn
          block
          large
          outlined
          type="button"
          color="secondary"
          v-track="'microsoft-sign-in-btn'"
          class="button font-weight-bold mt-4 text--secondary"
          @click="signInUserWithMicrosoftPopUp"
        >
          <img
            class="pr-3"
            width="30px"
            height="30px"
            alt="Micorsoft icon"
            id="microsoft-sign-in-login"
            :src="$images.microsoftIcon"
          />
          Sign in with microsoft
        </v-btn>
        <v-btn
          block
          large
          outlined
          type="button"
          color="secondary"
          v-track="'apple-sign-in-btn'"
          @click="signInUserWithApplePopUp"
          class="button font-weight-bold mt-4 text--secondary"
        >
          <img
            class="pr-3"
            width="35px"
            height="35px"
            alt="Apple icon"
            id="apple-sign-in-login"
            :src="$images.appleIcon"
          />
          Sign in with apple
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getMultiFactorResolver } from 'firebase/auth' 
import {
  AUTH_GIP_ERROR_CODES,
  AUTH_ERROR_CASE_MESSAGES,
  MFA_OPTIONS,
} from "@/constants/app";
import {
  auth,
  oauthProvider,
  googleAuthProvider,
  singInWithPopupProvider,
  useDeviceSpecificLanguage,
} from "@/services/auth";
import { isEmpty } from "@/utils";

/**
 * Auth 2.0 identity login buttons widget
 * @description Handles Auth 2.0 for different IDP providers
 */
export default {
  name: "AuthIdentityLoginWidget",
  /**
  |--------------------------------------------------
  | Custom event
  |--------------------------------------------------
  */
  emits: ["loading", "enforce-mfa", "error"],
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      userProfile: "auth/userProfile",
      isMfaEnabled: "auth/isMfaEnabled",
      previousUserProvider: "auth/previousUserProvider",
    }),
    /**
     * isCurrentUserMfaSetup
     * Determines that current user have mfa setup or not
     */
    isCurrentUserMfaSetup() {
      const [textBasedMfa] = MFA_OPTIONS;
      const index = MFA_OPTIONS.findIndex(
        ({ id }) => id === this.userProfile?.mfaProvider
      );

      // Handles edge case for text based mfa
      if (MFA_OPTIONS[index]?.id === textBasedMfa.id && !this.isMfaEnabled) {
        return false;
      }

      return index != -1;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setCurrentUserDetails: "auth/setCurrentUserDetails",
      getUserProfileDetails: "auth/getUserProfileDetails",
      setPreviousUserProvider: "auth/setPreviousUserProvider",
      setCurentUserMfaDetails: "auth/setCurentUserMfaDetails",
    }),
    ...mapMutations({
      setEnforceMfaUser: "auth/SET_ENFORCE_MFA_FOR_USER",
    }),
    /**
     * @emits enforce-mfa Initiates enforcing mfa for current user
     */
    enforceMfa() {
      this.$emit("enforce-mfa");
    },
    /**
     * @param {String} message Error message to be shown to the user
     * @emits error Show error message to the user
     */
    emitErrorMessage(message) {
      this.$emit("error", message);
    },
    /**
     * showLoader
     * @emits loading shows loader
     */
    showLoader(val) {
      this.$emit("loading", val);
    },
    /**
     * initiateMfaFlow
     * @emits initiate-mfa starts the MFA auth flow for current user
     */
    initiateMfaFlow(resolver) {
      this.$nextTick(() => {
        this.$emit("initiate-mfa", resolver);
      });
    },
    /**
     * Error Handler
     */
    errorHandler(error) {
      if (error?.code === AUTH_GIP_ERROR_CODES.mfaRequired) {
        let resolver  = getMultiFactorResolver(auth,error)
        this.initiateMfaFlow(resolver);
      } else if (AUTH_GIP_ERROR_CODES.accountAlreadyExists === error?.code) {
        const { credential, email } = error;
        this.setPreviousUserProvider({ credential, email });
        this.$router.push({
          name: "login",
          query: { reason: "socialUserNotFound" },
        });
        this.emitErrorMessage(AUTH_ERROR_CASE_MESSAGES.socialUserNotFound);
      } else {
        this.emitErrorMessage(
          AUTH_ERROR_CASE_MESSAGES[error?.code] ?? error?.message
        );
      }
    },
    async determineAndInitateMfaFlow(user) {
      await this.getUserProfileDetails({ email: user?.email });
      await this.setCurentUserMfaDetails();
      this.$nextTick(async () => {
        if (!this.isCurrentUserMfaSetup) {
          await this.setEnforceMfaUser(user);
        }
        this.enforceMfa();
      });
    },
    /**
     * @description Handles sign in flow of user with google
     */
    async signInUserWithGooglePopUp() {
      try {
        this.showLoader(true);
        this.emitErrorMessage("");

        useDeviceSpecificLanguage();
        const provider = googleAuthProvider();

        const result = await singInWithPopupProvider(provider);
        await this.determineAndInitateMfaFlow(result.user);
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.showLoader(false);
      }
    },
    /**
     * @description Handles sign in flow of user with microsoft
     */
    async signInUserWithMicrosoftPopUp() {
      try {
        this.showLoader(true);
        this.emitErrorMessage("");

        useDeviceSpecificLanguage();
        const provider = oauthProvider("microsoft.com");

        provider.setCustomParameters({ prompt: "select_account" });
        const result = await singInWithPopupProvider(provider);

        await this.determineAndInitateMfaFlow(result.user);
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.showLoader(false);
      }
    },
    /**
     * signInUserWithApplePopUp
     * @description Handles sign in with apple functionality
     */
    async signInUserWithApplePopUp() {
      try {
        this.showLoader(true);
        this.emitErrorMessage("");

        useDeviceSpecificLanguage();
        const provider = oauthProvider("apple.com");

        provider.setCustomParameters({ prompt: "select_account" });
        const result = await singInWithPopupProvider(provider);

        await this.determineAndInitateMfaFlow(result.user);
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.showLoader(false);
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mounted
  |--------------------------------------------------
  */
  mounted() {
    if (
      this.$route.query?.reason === "socialUserNotFound" &&
      !isEmpty(this.previousUserProvider)
    ) {
      this.emitErrorMessage(AUTH_ERROR_CASE_MESSAGES.socialUserNotFound);
    }
  },
};
</script>
