<template>
  <div class="loader">
    <template v-if="withOverlay">
      <v-overlay v-show="loading" absolute z-index="1" :color="overlayColor" />
      <v-progress-circular
        absolute
        indeterminate
        class="darken-1"
        :size="size"
        :color="color"
      />
    </template>
    <v-fade-transition v-else>
      <v-overlay v-show="loading" absolute z-index="1" :color="overlayColor">
        <v-progress-circular
          absolute
          indeterminate
          class="darken-1"
          :size="size"
          :color="color"
        />
      </v-overlay>
    </v-fade-transition>
  </div>
</template>
<script>
import appConfig from "@/config/app.config";

/**
 * Renders loader
 */
export default {
  name: "Loader",
  props: {
    /**
     * Shows and hide the loader
     */
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    withOverlay: { type: Boolean, default: false },
    /**
     * Backgroud color of the overlay
     */
    overlayColor: {
      type: String,
      default: "white",
    },
    color: {
      type: String,
      default: appConfig.loader.color,
    },
    /**
     * Size of the loader
     */
    size: {
      type: Number,
      default: appConfig.loader.size,
    },
  },
};
</script>
