<template>
  <div class="auth-form-buttons" :class="wrapperClasses">
    <v-row>
      <v-col lg="12" md="12" cols="12" class="d-flex justify-space-between">
        <v-btn
          x-large
          outlined
          @click="onBack"
          v-track="backBtnTrackId"
          class="px-12 font-weight-bold secondary--font"
        >
          Back
        </v-btn>
        <v-btn
          x-large
          :type="submitBtnType"
          color="dark-black"
          :loading="isLoading"
          :disabled="!isFormValid"
          v-track="submitBtnTrackId"
          @click="$emit('submit')"
          class="px-9 font-weight-bold secondary--font white--text btn-submit"
        >
          {{ submitBtnText }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * Auth form submit buttons
 */
export default {
  name: "AuthFormButtons",
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    isLoading: { type: Boolean, required: true, default: false },
    isFormValid: { type: Boolean, required: true, default: false },
    submitBtnText: { type: String, default: "Login" },
    submitBtnType: { type: String, default: "submit" },
    backBtnTrackId: { type: String, default: "" },
    submitBtnTrackId: { type: String, default: "" },
    wrapperClasses: { type: String, default: "" },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    onBack() {
      this.$emit("back");
    },
  },
};
</script>
